
import * as React from "react";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function WardResultsHero({wardName, wardImage}) {
  const image = getImage(wardImage);

  return (
    <div className="hero--results">

        <div className="hero-content">
          <h1>Results for <br/>{wardName}</h1>
        </div>

        <div className="image-wrapper"></div>
             
        <GatsbyImage
          image={image}
          className="hero--ward__picture"
          objectFit="cover"
          alt=""
          placeholder="blurred"
        />
          
    </div>
  )
}
